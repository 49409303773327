import React, { Component } from "react";
import Experience from "./Experience";

const company = "Cerner Corporation (Consulting for ECCO Select)";
const jobTitle = "Lead Architect";
const dateFrom = "June 2013";
const dateTo = "December 2013";
const description = () => (`
•	Launched and guided a specialized development team to create an innovative web-based workflow design platform.
`);
const accomplishments = () => (`
I was only there for a short time due to contract issues but did successfully launch the development team and started development. I heard about a year later that the project was a success.
`);
const skills = () => (`
Team Leadership, TypeScript, .NET, ASP.NET, SQL Server, Agile, Scrum
`);
class CernerEccoExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default CernerEccoExperience;