import React, { Component } from "react";
import Experience from "./Experience";

const company = "DST Systems";
const jobTitle = "Senior Project Leader";
const dateFrom = "October 2002";
const dateTo = "February 2010";
const description = () => (`
•	Led multiple agile software development teams.
•	Introduced developer skills and a rating system.
•	Spearheaded initiatives to enhance operational efficiency. 
•	Optimized the use of offshore resources for maximum productivity.
`);
const accomplishments = () => (`
As a result of having been given the deplorables of the division for my first team, I developed a new rating system for developers that improved team performance by 30%.
`);
const skills = () => (`
Agile, Scrum, Kanban, Team Leadership, .NET, SQL Server, C#, jQuery, JavaScript, CSS, HTML, ASP.NET, full-stack development, XML, XSLT, offshore resource management
`);
class DSTSystemsExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default DSTSystemsExperience;