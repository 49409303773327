import React from 'react';
import Section from '../sections/Section';

const TITLE = "Who I Am";
const CONTENT = (
  <p>Over the past twenty years, I've had the fortune of immersing myself in the software development world, benefiting immensely from my colleagues and various experiences. With a focus on positively influencing sectors like healthcare, education, and business, I've continually honed my skills to offer meaningful technological solutions. This journey has instilled a versatile approach, helping me remain committed to quality amidst evolving scenarios.</p>
);

class ProfessionalSummarySection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default ProfessionalSummarySection;
