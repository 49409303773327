import React, { Component } from "react";
import Experience from "./Experience";

const company = "DST Systems (Consulting for MCG)";
const jobTitle = "Research and Development Engineer";
const dateFrom = "September 2000";
const dateTo = "August 2001";
const description = () => (`
•	Contributed to the development of a drag-and-drop business process management tool.
•	Connected the Business Process Management tool to a backend process execution tool.
`);
const accomplishments = () => (`
I was able to contribute to the development of a new product that was a success and was used by the company for many years. Although I was only there for a year, I was able to make a significant contribution to the direction of the end product.
`);
const skills = () => (`
XML, XSLT, SQL Server, ASP, VBScript, JavaScript, HTML, CSS, SQL Server, .NET, C#, full-stack development, HttpRequests, Web Services
`);
class DSTSystemsMCGExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default DSTSystemsMCGExperience;