import React from 'react';
import Section from '../sections/Section';
import IllumisoftExperience from '../experiences/IllumisoftExperience';
import EpiqExperience from '../experiences/EpiqExperience';
import CSCExperience from '../experiences/CSCExperience';
import OneSRCEExperience from '../experiences/1SRCEExperience';
import AIConsultingProjectExperience from '../experiences/AIConsultantProjectExperience';
import CernerEccoExperience from '../experiences/CernerEccoExperience';
import DSTSystemsExperience from '../experiences/DSTSystemsExperience';
import CernerMCGExperience from '../experiences/CernerMCGExperience';
import DSTSystemsMCGExperience from '../experiences/DSTSystemsMCGExperience';
import CernerExperience from '../experiences/CernerExperience';

const TITLE = 'Experience';
const CONTENT = (
  <div>
    <OneSRCEExperience />
    <AIConsultingProjectExperience />
    <IllumisoftExperience />
    <EpiqExperience />
    <CernerEccoExperience />
    <CSCExperience />
    <DSTSystemsExperience />
    <CernerMCGExperience />
    <DSTSystemsMCGExperience />
    <CernerExperience />
  </div>
);

class ExperienceSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default ExperienceSection;
