import React from 'react';
import Section from '../Section';
import './TechnologySkillsListSection.css';

const skills = [
  '.NET',
  'Agile',
  'API Security',
  'App Center',
  'App Security',
  'ASP.NET',
  'Azure AD B2C',
  'Azure AD/Entra',
  'Azure AI Services',
  'Azure AI Studio',
  'Azure App Services',
  'Azure Cosmos DB',
  'Azure DevOps',
  'Azure Functions',
  'Azure Keystore',
  'Azure OpenAI',
  'Azure Security',
  'Azure SQL Server',
  'Azure Storage',
  'Azure – everything',
  'Blazer',
  'C#',
  'CSS',
  'GitHub Actions',
  'GitOps',
  'Jest',
  'JavaScript',
  'Msal',
  'Node',
  'PHP',
  'Python',
  'ReactJS',
  'Restful APIs',
];

const TITLE = 'Technologies';

const TechnologySkillsListSection = () => (
  <Section title={TITLE}>
    <div className="technology-skills-list">
      {skills.map((skill, index) => (
        <div key={index} className="technology-skill-item">
          {skill}
        </div>
      ))}
    </div>
  </Section>
);

export default TechnologySkillsListSection;
