import React from 'react';
import Section from './Section';

const TITLE = 'Certifications';
const CONTENT = (
  <ul>
    <li><strong>Microsoft Certified</strong>: Azure AI Engineer Associate</li>
    <li><strong>Issued</strong>: August 6, 2024</li>
    <li><strong>Credential ID</strong>: 9D13388E52F2DE97</li>
    <li><strong>Certification Number</strong>: 15586A-5E9O6F</li>
    <li><strong>Validation Link</strong>: <a href="https://learn.microsoft.com/en-us/users/danielprince-7660/credentials/certification/azure-ai-engineer?tab=credentials-tab" target="_blank" rel="noopener noreferrer">Microsoft Certified: Azure AI Engineer Associate</a></li>
  </ul>
);

class CertificationsSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default CertificationsSection;
