import React, { Component } from "react";
import Experience from "./Experience";

const company = "Epiq Systems";
const jobTitle = "Software Development Manager";
const dateFrom = "December 2013";
const dateTo = "June 2015";
const description = () => (`
•	Led a team focused on optimizing bankruptcy case management solutions, improving both team workflows and software features.
•	Revamped an intricate part of their case management software to remove the use of FILESTREAM in favor of distributed network drives, ensuring a smooth transition in the production environment.
`);
const accomplishments = () => (` 
Under my leadership, the team delivered several high-quality updates that saved the company an estimated $100 million over 10 years. Strategically transistioned several petabytes of data from FILESTREAM to distributed network drives without the loss of a single file.`);
const skills = () => (` 
C#, .NET, SQL Server, Agile, Scrum
`);

class EpiqExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default EpiqExperience;