import React from 'react';
import Section from '../sections/Section';

const TITLE = 'Key Competencies';
const CONTENT = (
  <ul>
    <li>IT Planning & Strategy</li>
    <li>Software Project Leadership & Management</li>
    <li>Mobile Technology Integration</li>
    <li>AI Strategy and Implementation</li>
    <li>Team Mentoring, Leadership, & Development</li>
    <li>Stakeholder Collaboration & Engagement</li>
    <li>Innovative Problem Resolution</li>
    <li>Effective Written and Verbal Communication</li>
    <li>Analytical Mindset & Solution Orientation</li>
    <li>Commitment to Contiual Growth</li>
  </ul>
);

class KeyCompetenciesSection extends Section {
  render() {
    return (
      <Section title={TITLE}>
        {CONTENT}
      </Section>
    );
  }
}

export default KeyCompetenciesSection;
