import React, { Component } from "react";
import Experience from "./Experience";

const company = "Cener Corporation (Consulting for MCG)";
const jobTitle = "Team Lead";
const dateFrom = "August 2001";
const dateTo = "October 2002";
const description = () => (`
•	Hired, trained, and led a team of four excellent developers.
•	Created an XML-driven web solution for sales calculation. 
•	Created a custom Database-query-to-XML converter.
`);
const accomplishments = () => (`
Hired an excellent team of developers and created a custom solution that had the potential to increase revenue and save the company over $1 million in the first year.
`);
const skills = () => (`
XML, XSLT, SQL Server, ASP, VBScript, JavaScript, HTML, CSS, Team Leadership, SQL Server, .NET, C#, full-stack development, database query optimization
`);
class CernerMCGExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default CernerMCGExperience;