import React, { Component } from "react";
import Experience from "./Experience";

const company = "AIConsultant Project";
const jobTitle = "Founder, Lead Developer";
const dateFrom = "June 2023";
const dateTo = "Present";
const description = () => (`
•	Designed and implemented a secure LLM interface with system registration and authorization using Azure AI Services and Azure Function Apps.
•	Utilized Azure B2C Tenant, Azure Key Vault, and Azure Storage to enhance system security and data management.
•	Developed the AIConsultant Project to explore the potential of LLMs in solving complex user problems through personalized AI responses.
`);
const accomplishments = () => (`
This project has proven to be helpful to younger users who are looking for answers to questions they may not feel comfortable asking their parents or teachers. The project has given me a ton of experence in implementing AI Services and the extent to which you can control the output by using prompt chaining, and othe prompt engineering approaches.
`);
const skills = () => (`
Azure AI Services, Azure Function Apps, Azure B2C Tenant, Azure Key Vault, Azure Storage, Python, JavaScript, Node.js, React, HTML, CSS, Git, GitHub, Prompt Engineering, Embedding Engineering
`);
class AIConsultingProjectExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default AIConsultingProjectExperience;