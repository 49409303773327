import React, { Component } from "react";
import Experience from "./Experience";

const company = "Cerner Corporation";
const jobTitle = "Engineering Team Lead";
const dateFrom = "December 1997";
const dateTo = "September 2000";
const description = () => (`
•	Ascended from a developer to eventually oversee cross functional teams to bring to life a solution for patients called IQHealth – an innovative patient-centric portal for electronic medical records.
`);
const accomplishments = () => (`
I was lucky enough to be put in with a team of people who didn't know how to collaborate and my ability to pull people together propelled my career. I was able to lead the team to create a product that was a success and is a version of it is still in use today.
`);
const skills = () => (`
C++, Visual C++, DHTML, JavaScript, SQL Server, ASP, VBScript, HTML, CSS, Team Leadership
`);
class CernerExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default CernerExperience;