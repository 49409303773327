import React from 'react';
import './Experience.css';

class Experience extends React.Component {
  // Helper method to convert a string with bullet points into a list
  renderAsList = (text) => {
    // Split the text into lines and filter out empty lines
    const items = text
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line.startsWith('•')); // Only include lines that start with "•"

    // Return a list if there are items, otherwise return plain text
    return items.length > 0 ? (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item.slice(1).trim()}</li> // Remove the "•" and trim extra spaces
        ))}
      </ul>
    ) : (
      <p>{text}</p>
    );
  };

  render() {
    return (
      <div className="experience">
        <div className="experience-company">
          {this.props.company} - {this.props.jobTitle}
        </div>
        <div className="experience-date">
          {this.props.dateFrom} - {this.props.dateTo}
        </div>
        <div className="experience-description">
          {this.renderAsList(this.props.description())}
        </div>
        <div className="experience-accomplishments">
          <strong>Accomplishments:</strong>
          {this.renderAsList(this.props.accomplishments())}
        </div>
        <div className="experience-skills">
          <strong>Primary Technologies:</strong>
          <p>{this.props.skills()}</p>
        </div>
      </div>
    );
  }
}

export default Experience;
