import React, { Component } from "react";
import Experience from "./Experience";

const company = "illumisoft";
const jobTitle = "Lead Architect, CEO";
const dateFrom = "August 2014";
const dateTo = "March 2023";
const description = () => (`
•	Led a small, dedicated team to deliver high-performing solutions while simplifying and conveying complex technical concepts for clients with varying levels of technology adeptness.
•	Directed resource allocation efficiently, fostering a culture of productivity and ownership within a remote-ready environment.
•	Maintained rigorous adherence to healthcare standards and regulations.
•	Designed and implemented enterprise-level Azure solutions, including CI/CD pipelines, ensuring stakeholder alignment and adaptability to evolving client requirements.
•	Monitored solution development and guided developers in code configuration and management.
`);
const accomplishments = () => (`
At our peak, we had annual revenue of over 3 million, 17 people working with 
us, and completed over dozens of successful projects.
`);
const skills = () => (`
Azure, React, Node.js, C#, .NET, SQL Server, Agile, Scrum, Kanban, CI/CD, Azure DevOps, React Native, Azure App Services, Azure Functions, ASP.NET, full-stack development, cloud architecture, healthcare standards and regulations
`);

class IllumisoftExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default IllumisoftExperience;