import React, { Component } from "react";
import Experience from "./Experience";

const company = "CSC";
const jobTitle = "Solution Architect";
const dateFrom = "January 2011";
const dateTo = "June 2014";
const description = () => (`
•	Assumed leadership in the development of multiple documentation tools, uniting a fragmented group of developers into a streamlined, agile team. 
•	Forged strong collaborations with GS-13 TraDoc officers, earning accolades for exceptional performance.
`);
const accomplishments = () => (`
My efforts resulted in the successful delivery of several key solutions that significantly contributed to client satisfaction and contract success. 
`);
const skills = () => (` 
Azure, React, Node.js, C#, .NET, SQL Server, Agile, Scrum, Kanban
`);

class CSCExperience extends Component {
    render() {
        return (
            <Experience
                company={company}
                jobTitle={jobTitle}
                dateFrom={dateFrom}
                dateTo={dateTo}
                description={description}
                accomplishments={accomplishments}
                skills={skills} />
        );
    }
}

export default CSCExperience;